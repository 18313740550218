import {CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";
import React from "react";

import Storage from "../utils/Storage";
import AppError from "../error/AppError";
import YesNoDialog from "./structure/components/modal/others/YesNoModal";

import Loader from "./structure/components/loader/Loader";
import InfoBar from "./structure/components/modal/others/InfoBar";
import ModalChart from "./structure/components/modal/ModalChart";

const _ = require('lodash');

export default class BaseScreen extends React.Component {

    constructor() {
        super();

        this.state = {
            error: null
        }

        this._loader = React.createRef();
        this._yesNoDialog = React.createRef();
        this._infoBar = React.createRef();
        this._modalChart = React.createRef();

        this.showError = this.showError.bind(this);
        this.hideError = this.hideError.bind(this);
    }

    getSystemBlog(name) {
        const blogs = Storage.get('blogs');
        return _.find(blogs, it => {
            return it.blog_name === name;
        })
    }

    componentDidMount() {
    }

    ask(message, onYes) {
        // onYes
        this._yesNoDialog.current.show(message, onYes);
    }

    showLoader() {
        this._loader.current.show();
    }

    hideLoader() {
        if (this._loader.current) {
            this._loader.current.hide();
        }
    }

    showChart(config){
        return this._modalChart.current.show(config);
    }

    baseHtml() {
        const html = [];
        html.push(<CModal
            show={!!this.state.error}
            onClose={this.hideError}
            color="danger"
            key={"CModal"}
        >
            <CModalHeader closeButton>
                <CModalTitle>{this.state.error ? this.state.error.title : ''}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                {this.state.error ? this.state.error.message : ''}
            </CModalBody>
            <CModalFooter>
                {/*<CButton color="danger" onClick={() => setDanger(!danger)}>Do Something</CButton>{' '}*/}
                {/*<CButton color="secondary" onClick={() => setDanger(!danger)}>Cancel</CButton>*/}
            </CModalFooter>
        </CModal>)
        html.push(<Loader ref={this._loader} key={"Loader"}/>);
        html.push(<InfoBar ref={this._infoBar} key={"ErrorBar"}/>);
        html.push(<YesNoDialog ref={this._yesNoDialog}/>)
        html.push(<ModalChart ref={this._modalChart}/>)
        return html;
    }

    showError(err, title, message) {
        console.log(err);
        if (err instanceof AppError) {
            message = err.message;
        }

        this.setState({
            ...this.state,
            error: {
                title: title,
                message: message
            }
        });
    }

    showErrorBar(message) {
        return this._infoBar.current && this._infoBar.current.showError(message);
    }

    showLoadingBar(message) {
        return this._infoBar.current && this._infoBar.current.showLoading(message);
    }

    hideLoadingBar(id) {
        return this._infoBar.current && this._infoBar.current.hideLoading(id);
    }

    hideError() {
        this.setState({
            ...this.state,
            error: null
        });
    }
}
