import React from 'react'
import moment from "moment";

const _ = require('lodash');
const Chart = require('chart.js');

export default class RestChart extends React.Component {

    constructor() {
        super();
        this.logChartCanvasRef = null;
    }

    // componentDidMount() {
    //     this.createChart([120, 350, 156, 120], [new Date().getTime(), new Date().getTime() + 1000 * 10, new Date().getTime() + 1000 * 50, new Date().getTime() + 1000 * 500]);
    // }

    createChart(durationsArr, datesArr, graphPrefs) {
        const maxY = _.max(durationsArr) * 1.2;
        const stepY = Math.round(maxY / 15); //graphPrefs.step;
        const maxDuration = _.max(durationsArr);
        const maxDurationRounded = Math.ceil(maxDuration / stepY) * stepY; // round up to stepY
        const ctx = this.logChartCanvasRef.getContext('2d');
        const pointBackgroundColor = 'rgba(118, 183, 3, 1)';
        const backgroundColor = 'rgba(118, 183, 3, 0.1)';
        const borderColor = 'rgba(118, 183, 3, 0.5)';

        const data = {
            labels: datesArr,
            datasets: [{
                label: null,
                lineTension: 0.2,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                pointBackgroundColor: pointBackgroundColor,
                data: durationsArr,
            }],
        };
        const options = {
            responsive: true,
            pointDotRadius: 20,
            legend: {display: false},
            tooltips: {
                enabled: true,
                mode: 'label',
                callbacks: this.props.callbacks,
            },
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'Datum',
                    },
                    ticks: {
                        display: false, // hide dates
                        stepSize: 0,
                    },
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'Trvání'
                    },
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: maxDuration > maxY ? maxDurationRounded : maxY,
                        stepSize: stepY,
                    },
                }],
            },
        };

        if (this.chart && this.chart.dispose) {
            this.chart.dispose();
            this.chart.destroy();
        }

        this.chart = Chart.Line(ctx, {
            options: options,
            data: data,
        });
    }

    render() {
        return (
            <div style={{height: this.props.height}}>
                <canvas
                    style={{width: this.props.width, height: this.props.height}}
                    key={this.props.id}
                    id={this.props.id}
                    ref={r => {
                        this.logChartCanvasRef = r;
                    }}
                />
            </div>
        )
    }

}

