import React from "react";
import Http from "../../../../utils/Http";
import moment from "moment";
import RestChart from "../misc/RestChart";

const md5 = require('md5');

const _ = require('lodash');

export default class ReactRestChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            style: {
                height: '200px',
                width: '100%'
            }
        };

        this._chartTableRows = React.createRef();
    }


    load(config) {
        const query = Http.query(config.query);
        return Http.get(`${config.url}?${query}`).then(data => {
            const y = _.map(data.list, config.yColumn);
            const x = _.map(data.list, config.xColumn);

            this.setState({
                ...this.state,
                ...config,
            }, () => {
                this._chartTableRows.current.createChart(y, x, {});
            });

            return {x, y};
        });

    }

    render() {
        return (
            <div style={{...this.state.style}}>
                <RestChart key={md5(this.state.url || 'chart')} id={md5(this.state.url || 'chart')} callbacks={{
                    label: tlItems => {
                        return `${tlItems.yLabel} ${this.state.yLabel}`;
                    },
                    title: tlItems => {
                        return moment(tlItems.xLabel).format("DD.MM HH:mm:ss");
                    }
                }} ref={this._chartTableRows} width={'100%'} height={'200px'}/>
            </div>
        )
    }
}
