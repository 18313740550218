class Storage {
    constructor() {
        this.data = {}
    }

    setItem(key, value) {
        if (typeof value === 'object' && value !== null) {
            return localStorage.setItem(key, JSON.stringify(value));
        }
        localStorage.setItem(key, value);
    }

    getItem(key, value) {
        const out = localStorage.getItem(key);
        try {
            return JSON.parse(out);
        } catch (err) {
            return out;
        }
    }

    set(key, value) {
        this.data[key] = value;
    }

    get(key, defaultValue = null) {
        const out = this.data[key];
        if (typeof out !== 'undefined') {
            return out
        }
        return defaultValue;
    }

    clear(key) {
        delete this.data[key];
    }
}

const storage = new Storage();
export default storage;
