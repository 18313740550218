import BaseScreen from "../../../BaseScreen";
import Http from "../../../../utils/Http";
import moment from "moment";

export default class ReservationScreen extends BaseScreen {

    constructor(props) {
        super(props);

        this.deleteEvent = this.deleteEvent.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        const searchComponent = window.searchComponent;
        searchComponent && searchComponent.hide();
    }

    loadReservations(mrId, from, to) {
        return Http.get(`/meeting-rooms/${mrId}/reservations?filter=start<gt>"${from.getTime()}"<and>end<lt>"${to.getTime()}"`).then(data => {
            for (const eve of (data.list || [])) {
                eve.start = new Date(eve.start);
                eve.end = new Date(eve.end);
                // eve.title = `${eve.title} ${moment(eve.start).format('HH:mm')}-${moment(eve.end).format('HH:mm')}`;
            }

            return data.list || [];
        });
    }

    lodMeetingRoomState(mrId) {
        return Http.get(`/meeting-rooms/${mrId}/schedule`).then(data => {
            return data;
        });
    }

    deleteEvent(mrsId) {
        return Http.delete(`/meeting-rooms/reservations/${mrsId}`).then(data => {
            return data;
        });
    }

    saveEvent(event) {
        return Http.post(`/meeting-rooms/${event.mr_id}/reservations/`, event).then(() => {
            this.reloadReservations(event.mr_id);
        });
    }

    getFromTo(date, view) {
        let start;
        let end;

        // week month
        if (view === 'month') {
            start = new Date(date.getTime());
            start.setDate(1);
            end = new Date(start.getTime() + 1000 * 60 * 60 * 24 * 31);
        } else if (view === 'week') {
            start = new Date(date.getTime() - 1000 * 60 * 60 * 24 * ((date.getDay() || 7) - 1));
            end = new Date(date.getTime() + 1000 * 60 * 60 * 24 * (7 - (date.getDay() || 7)));

            start.setHours(0);
            start.setMinutes(0);
            end.setHours(23);
            end.setMinutes(59);
        } else if (view === 'day') {
            start = new Date(date.getTime());
            start.setHours(0);
            start.setMinutes(0);

            end = new Date(date.getTime());
            end.setHours(23);
            end.setMinutes(59);
        } else if (view === 'agenda') {
            start = new Date(date.getTime());
            end = new Date(start.getTime() + 1000 * 60 * 60 * 24 * 31 * 1);
        }

        return [start, end]
    }
}
