import axios from 'axios/index';
import AppError from '../error/AppError';
import Storage from './Storage'

const FileDownload = require('js-file-download');

const codes = {
    UNKNOWN_ERROR: "Neznámá chyba.",
    INVALID_TOKEN: "Neplatný přihlašovací token.",
    UNAUTHORIZED: "Nejste přihlášen.",
    INVALID_USER_NAME: "Neplatné uživatelské jméno.",
    FOLDER_NOT_FOUND: "Složka nenalezena.",
    FILE_NOT_FOUND: "Soubor nenalezen.",
    INVALID_USER_STATE: "Neplatný stav uživatele.",
    BAD_REQUEST: "Chybný požadavek.",
    USER_GROUP_CANNOT_BE_EDITED: "Uživatelská skupina nemůže být smazána.",
    FORBIDDEN: "Přístup zamítnut.",
    NOT_ENABLED: "Tato operace není povolena.",
    NOT_FOUND: "Položka nebyla nalezena.",
    CALCULATION_ERROR: "Chyba výpočtu.",
    CALCULATION_WORKFLOW_ERROR: "Chyba výpočtu.",
    VOTE_FINISHED: "Hlasování bylo již ukončeno.",
    VOTE_IS_NOT_PUBLIC: "Hlasování není veřejné.",
    MISSING_MEETING_ROOM_NAME: "Název zasedací místnosti nebyl vyplněn.",
    MISSING_MEETING_ROOM_RESERVATION_TITLE: "Titulek události nebyl vyplněn.",
    GROUP_NAME_ALREADY_EXISTS: "Název skupiny již existuje.",
    BAD_USER_NAME_OR_PASSWORD: "Neplatné přihlašovací jméno nebo heslo.",
    MEETING_ROOM_OVERLAP: 'Rezervace se překrývá s jinou rezervací a nemůže být založena.'
}

class Http {

    post(url, body) {
        try {
            const instance = axios.create({
                baseURL: window.appConfig().backend,
                timeout: 10000,
                headers: {
                    "Authorization": `Bearer ${Storage.get("token")}`
                }
            });

            return instance.post(url, body).then(res => {
                return res.data;
            }).catch(err => {
                this.onError(err);
            })
        } catch (err) {
            return Promise.reject(err);
        }
    }

    put(url, body) {
        try {
            const instance = axios.create({
                baseURL: window.appConfig().backend,
                timeout: 10000,
                headers: {
                    "Authorization": `Bearer ${Storage.get("token")}`
                }
            });

            return instance.put(url, body).then(res => {
                return res.data;
            }).catch(err => {
                this.onError(err);
            })
        } catch (err) {
            return Promise.reject(err);
        }
    }

    get(url, baseUrl = null) {
        try {
            const instance = axios.create({
                baseURL: baseUrl || window.appConfig().backend,
                timeout: 10000,
                headers: {
                    "Authorization": `Bearer ${Storage.getItem("token")}`
                }
            });

            return instance.get(url).then(res => {
                return res.data;
            }).catch(err => {
                this.onError(err);
            })
        } catch (err) {
            return Promise.reject(err);
        }
    }

    delete(url) {
        try {
            const instance = axios.create({
                baseURL: window.appConfig().backend,
                timeout: 10000,
                headers: {
                    "Authorization": `Bearer ${Storage.get("token")}`
                }
            });

            return instance.delete(url).then(res => {
                return res.data;
            }).catch(err => {
                this.onError(err);
            })
        } catch (err) {
            return Promise.reject(err);
        }
    }

    download(url, fileName) {
        return axios({
            baseURL: window.appConfig().backend,
            timeout: 1000 * 60 * 60 * 10,
            url: url,
            method: 'GET',
            responseType: 'blob',
            headers: {
                "Authorization": `Bearer ${Storage.get("token")}`
            }
        }).then((response) => {
            return FileDownload(response.data, fileName);
        }).catch(err => {
            return this.onError(err);
        });
    }

    upload(url, data, uploadProgress) {
        try {
            const instance = axios.create({
                baseURL: window.appConfig().backend,
                timeout: 1000 * 60 * 60 * 10,
                headers: {
                    "Authorization": `Bearer ${Storage.get("token")}`,
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: uploadProgress
            });

            return instance.post(url, data).then(res => {
                return res.data;
            }).catch(err => {
                this.onError(err);
            })
        } catch (err) {
            return Promise.reject(err);
        }

    }

    onError(err) {
        if (err?.response?.status === 401) {
            if (err.response.data.redirect) {
                window.location = err.response.data.redirect;
                throw new AppError("redirect");
            }
        }

        if (err?.response && err.response.data && err.response.data.code === 'UNAUTHORIZED') {
            window.location = '/#/login'
            return;
        }

        const code = err?.response?.data?.code;
        if (code) {
            if (code === 'CALCULATION_WORKFLOW_ERROR') {
                throw new AppError(err?.response?.data?.error_message);
            }

            const message = codes[code];
            throw new AppError(message || "Neznámá chyba.");
        }

        throw new AppError("Neznámá chyba.");
    }

    query(obj) {
        const keys = Object.keys(obj);
        const pieces = [];
        for (const key of keys) {
            pieces.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
        }
        return pieces.join('&');
    }

    translateErrorMessage(err, message) {
        if (err instanceof AppError) {
            message = err.message;
        }

        return message;
    }
}

const http = new Http();
export default http;
