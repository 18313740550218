import {CModal, CModalBody, CModalHeader, CModalTitle, CRow} from "@coreui/react";
import React from "react";
import {MoonLoader} from "react-spinners";

export default class Loader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            title: 'Načítám data'
        }

        this.hide = this.hide.bind(this);
        this.show = this.show.bind(this);
    }


    hide() {
        this.setState({
            ...this.state,
            visible: false,
        });
    }

    show(title = 'Načítám data') {
        this.setState({
            ...this.state,
            visible: true,
            title: title
        });
    }


    render() {
        const override = `
          display: block;
          margin: 0 auto;
          border-color: #aaa;
        `;

        return (
            <CModal
                closeOnBackdrop={false}
                size="xl"
                show={this.state.visible}
                onClose={this.hide}
                color="secondary"
            >
                <CModalHeader closeButton>
                    <CModalTitle>
                        {this.state.title}
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <br/><br/><br/>
                    </CRow>
                    <CRow>
                        <MoonLoader
                            color={'#333'} loading={true} css={override} size={60}/>
                    </CRow>
                    <CRow>
                        <br/><br/><br/>
                    </CRow>
                </CModalBody>
            </CModal>
        )
    }
}
