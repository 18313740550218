import React from "react";
import CIcon from "@coreui/icons-react";
import './tablepanel.scss'
import Tabs from "./TabButtons";

const _ = require('lodash');

export default class TablePanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            buttons: this.props.buttons || [],
            deactivatedButtons: [],
            activeTab: this.props.tabs[0]
        }

        this._tabs = React.createRef();
    }

    deactivate(buttons) {
        this.setState({
            ...this.state,
            deactivatedButtons: buttons
        });
    }

    getTab() {
        return this.state.activeTab;
    }

    activate(buttons) {
        const deactivated = _.cloneDeep(this.state.deactivatedButtons)
        _.subtract(deactivated, buttons);
        this.setState({
            ...this.state,
            deactivatedButtons: [deactivated]
        });
    }

    activateTab(tab){
        this._tabs.current.activateTab(tab);
    }

    isDeactivated(it) {
        return this.state.deactivatedButtons.indexOf(it.id) !== -1;
    }

    render() {
        return <div className={"flex-col"} style={{marginTop: `-1rem`}}>
            <div className={"left"} style={{flexDirection: 'row', display: 'flex', alignItems: 'flex-bottom'}}>
                <Tabs ref={this._tabs} buttons={this.props.tabs} onTabChange={tab => {
                    this.setState({
                        ...this.state,
                        activeTab: tab
                    }, () => {
                        this.props.onTabChange && this.props.onTabChange(tab);
                    });
                }}/>
            </div>
            <div className={"center"}>

            </div>

            <div className={"right"} style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>

                {this.state.activeTab && this.state.buttons[this.state.activeTab.id] && this.state.buttons[this.state.activeTab.id].map(button => {
                    const style = {}
                    if (this.isDeactivated(button)) {
                        style.color = '#bbb';
                    }

                    return (<div style={style}
                                 onClick={this.isDeactivated(button) ? null : this.props.onClick.bind(this, this.state.activeTab, button)}
                                 className={"tablePanelButton"}>
                        <CIcon name={button.icon} className={"tablePanelButtonIcon"} height={30}
                               style={style}/>{button.title}
                    </div>)
                })}

            </div>
        </div>
    }
}
