import React from "react";

import Storage from '../../../../utils/Storage';
import {CLink, CPopover} from "@coreui/react";

export default class UserStatus extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOnline: false
        };
        this.socket = Storage.get('socket');
        this.users = Storage.get('users');

        // user_id

        this.disconnected = this.disconnected.bind(this);
        this.logged = this.logged.bind(this);
        this.userConnected = this.userConnected.bind(this);
        this.userDisconnected = this.userDisconnected.bind(this);
    }

    componentDidMount() {
        this.socket.on('disconnect', this.disconnected);
        this.socket.on('logged', this.logged);
        this.socket.on('user_disconnect', this.userDisconnected);
        this.socket.on('user_connected', this.userConnected);

        const me = !this.props || !Storage.get('user') || this.props.user_id === Storage.get('user').user_id || !this.props.user_id;
        this.setState({
            ...this.state,
            isOnline: me ? this.socket.isConnected() : !!this.users.isOnline(this.props.user_id),
        });
    }

    componentWillUnmount() {
        this.socket.removeListener('disconnect', this.disconnected, false);
        this.socket.removeListener('logged', this.logged, false);
        this.socket.removeListener('user_disconnect', this.userDisconnected, false);
        this.socket.removeListener('user_connected', this.userConnected, false);
    }

    userDisconnected(data) {
        if (this.props && data.user_id === this.props.user_id) {
            this.disconnected();
        }
        console.log("Connected.");
        console.log(data);
    }

    userConnected(data) {
        console.log(data);
        if (this.props && data.user_id === this.props.user_id) {
            this.logged();
        }
    }

    disconnected() {
        this.setState({
            ...this.state,
            isOnline: false,
        });
    }

    logged() {
        this.setState({
            ...this.state,
            isOnline: true,
        });
    }

    render() {
        return (
            <CPopover header="Protokol socket-io" content={<div>
                Tato ikona znázorňuje připojení uživatele k aplikačnímu serveru.
                <br/><br/>
                Připojený uživatele dostává okamžité upozornění např. na chat, notifikace apod.
                <br/><br/>
                Pokud je ikona červená, může pomoci refresh stránky stisknutím <b>CTRL + R</b>.
            </div>}>
                <CLink>
                    {this.props.forAvatar ?
                        <span className={`c-avatar-status ${this.state.isOnline ? 'bg-success' : 'bg-danger'}`}/> :
                        <div className={this.state.isOnline ? "status-online" : 'status-offline'}
                             style={this.props.style}/>}

                </CLink>
            </CPopover>

        )
    }
}
