class DateUtils {

    get(date) {
        if (!date) {
            return null;
        }
        // TODO serverDifference
        return new Date(date);
    }

    getSeconds(date) {
        try {
            const now = new Date();
            return (date.getTime() - now.getTime()) / 1000;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    formatSeconds(sec, showFormat = null) {
        sec = Math.floor(sec);

        let minutes = Math.floor(sec / 60);
        sec = sec - minutes * 60;

        let hours = Math.floor(minutes / 60);
        minutes -= 60 * hours;

        let days = Math.floor(hours / 24);
        hours -= 24 * days;

        if (showFormat) {
            return (days > 0 ? `${days}d ` : '') + (hours > 0 ? `${hours}h ` : '') + (minutes > 0 ? `${minutes}m ` : '') + (sec > 0 ? `${sec}s` : '');
        } else {
            return (days > 0 ? `${days} dní ` : '') + (hours > 0 ? `${hours} hodin ` : '') + (minutes > 0 ? `${minutes} minut ` : '') + (sec > 0 ? `${sec} sekund` : '');
        }
    }

}

const du = new DateUtils();
export default du;
