import {EventEmitter} from 'events';
import Http from "./Http";
import Storage from './Storage';

const socketio = require('socket.io-client');


export default class Socket extends EventEmitter {

    connectSocket(userData) {
        this.socket = socketio(window.appConfig().backend, {
            reconnection: true,
            reconnectionDelay: 10000,
            reconnectionDelayMax: 15000,
            reconnectionAttempts: 0,
            forceNew: false
        });

        // Events
        this.socket.on('connect', () => {
            this.doSocketLogin(userData);
        });

        this.socket.on('disconnect', () => {
            this.emit('disconnect');
        });
        this.socket.on('error', (data) => {
            this.emit('error', data);
        });

        /**
         * Default messaging between client-server
         */
        this.socket.on('message', data => {
            try {
                // console.log("message");
                // console.log(data);
                const key = data.key;

                this.emit(key, data);
                this.emit('message', data);
            } catch (err) {
                console.log(err);
                // window.showError(Http.translateErrorMessage(err, 'Chyba komunikace socket-io protokolu.'));
            }
        });

        /**
         * User connected and logged into server. Http authentication before.
         */
        this.socket.on('logged', (data) => {
            try {
                data.serverTimeDiff = (new Date(data.server_date)).getTime() - (new Date()).getTime();
                Storage.set('userData', data);
                this.emit('logged', data);
            } catch (err) {
                console.log(err);
                // window.showError(Http.translateErrorMessage(err, 'Chyba komunikace socket-io protokolu.'));
            }
        });

        // this.doSocketLogin(userData);
    }

    isConnected() {
        if (!this.socket) {
            return false;
        }
        return this.socket.connected;
    }

    disconnect() {
        if (!this.socket) {
            return;
        }
        this.socket.disconnect();
    }

    message(key, data) {
        if (!this.socket) {
            return;
        }

        if (!data) {
            data = {}
        }

        this.socket.emit(key, {
            token: Storage.get('token'),
            value: JSON.stringify(data)
        });
    }

    doSocketLogin(userData) {
        this.message("login", {
            user_id: userData.user_id,
        });
    }

}
