import {
    CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from "@coreui/react";
import React from "react";
import ReactRestChart from "../chart/ReactRestChart";

const _ = require('lodash');

export default class ModalChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            title: 'Graf'
        }


        this.hide = this.hide.bind(this);
        this.show = this.show.bind(this);

        this._chart = React.createRef();
    }

    hide() {
        this.setState({
            ...this.state,
            visible: false,
        });
    }

    show(config) {
        this._config = config;
        return this._chart.current.load(config).then(data => {
            const avg = _.sum(data.y) / data.y.length;
            this.setState({
                ...this.state,
                yAvg: avg,
                ...config,
                visible: true,
            })
        });


    }

    reload() {
        this.show(this._config);
    }

    setLimit(limit) {
        this._config = {...this._config, query: {...this._config.query, limit: limit}}
        this.reload();
    };

    render() {
        const limits = [5, 10, 50, 100, 500, 1000, 5000, 10000, 50000, 100000, 500000];

        return (
            <CModal
                closeOnBackdrop={false}
                size="xl"
                show={this.state.visible}
                onClose={this.hide}
                color="success"
                key={"PopupTable"}
            >

                <CModalHeader closeButton>
                    <CModalTitle>
                        {this.state.title}
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                        <CDropdown>
                            <CDropdownToggle caret color={'success'}>
                                Počet záznamů grafu {this.state?.query?.limit || 100}
                            </CDropdownToggle>
                            <CDropdownMenu>
                                {limits.map(it => {
                                    return (<CDropdownItem onClick={this.setLimit.bind(this, it)}>{it}</CDropdownItem>)
                                })}
                            </CDropdownMenu>
                        </CDropdown>
                    </div>
                    <br/>
                    <ReactRestChart ref={this._chart}/>
                    Průměr {(this.state.yAvg || 0).toFixed(0)} {this.state.yLabel}
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={this.hide}>Zavřít</CButton>
                </CModalFooter>
            </CModal>
        )
    }
}
