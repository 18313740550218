import React from 'react'
import {Calendar as ReactCalendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/cs';
import '../../css/react-big-calendar.css'

const localizer = momentLocalizer(moment)

export default class Calendar extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <ReactCalendar
                    messages={{
                        date: 'Datum',
                        time: 'Čas',
                        event: 'Událost',
                        allDay: 'Celý den',
                        week: 'Týden',
                        work_week: 'Pracovní týden',
                        day: 'Den',
                        month: 'Měsíc',
                        previous: 'Předchozí',
                        next: 'Další',
                        yesterday: 'Včera',
                        tomorrow: 'Zítra',
                        today: 'Dnes',
                        agenda: 'Události',
                        noEventsInRange: 'V tomto rozmezí nejsou žádné události.',
                        showMore: function showMore(total) {
                            return "+" + total + " další";
                        }
                    }}
                    localizer={localizer}
                    {...this.props}
                />
            </>
        )
    }

}

