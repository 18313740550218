import {Component} from "react";
import './errorBoundary.scss'

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
            error,
        };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service like AppSignal
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        const {hasError, error} = this.state;

        if (hasError) {
            return (
                <div>
                    <div className={'errorBoundaryTitle'}>Oups, něco se pokazilo.</div>

                    <div className={'errorBoundaryBody'}>
                        <b>Error:</b> {error.message && <div>{error.message}</div>}
                        <b>Stack:</b> {error.stack && <p>{error.stack}</p>}
                    </div>

                    <div className={'errorBoundaryAuthor'}>
                        ⚠️⚠️ Tuto chybu prosím vyfoťte a pošlete @Michal Fuka (michal.fuka@neit.group). ⚠️⚠️
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;