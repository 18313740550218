import {EventEmitter} from 'events';

export default class Users extends EventEmitter {

    constructor(socket) {
        super();

        this.users = [];
        this.usersMap = new Map();

        this.onMessage = this.onMessage.bind(this);

        // On logged, some users already connected.
        socket.on('logged', user => {
            this.iniUsers(user.users);
        });
        socket.on('message', this.onMessage);
    }

    onMessage(data) {
        const key = data.key;
        if (key === 'user_connected') {
            this.onConnected(data);
        } else if (key === 'user_disconnect') {
            this.onDisconnected(data);
        }
        this.emit();
    }

    getUsers() {
        return this.users;
    }

    iniUsers(users) {
        if (Array.isArray(users) && users.length > 0) {
            for (const user of users) {
                this.addUser(user);
            }
        }
    }

    getById(id) {
        if (this.usersMap.has(id)) {
            return this.usersMap.get(id);
        }

        return null;
    }

    getIndex(id) {
        for (let i = 0; i < this.users.length; i += 1) {
            if (this.users[i].user_id === id) {
                return i;
            }
        }
        return null;
    }

    addUser(user) {
        // Edit loggedDate
        const index = this.getIndex(user.user_id);
        if (index != null) {
            this.users[index] = user;
        } else {
            this.users.push(user);
        }

        this.usersMap.set(user.user_id, user);
    }

    /**
     * Handle user connected.
     * @param data
     */
    onConnected(data) {
        if (!data.user_id) {
            console.log("Unknown user connected " + JSON.stringify(data));
            return;
        }

        this.addUser(data);
    }

    /**
     * Handle user disconnected.
     * @param data
     */
    onDisconnected(data) {
        if (!data.user_id) {
            console.log("Unknown user disconnected " + JSON.stringify(data));
            return;
        }

        const newElements = [];
        for (let i = 0; i < this.users.length; i += 1) {
            if (this.users[i].user_id !== data.user_id) {
                newElements.push(this.users[i])
            } else {
                this.usersMap.delete(data.user_id);
            }
        }
        this.users = newElements;
    }

    isOnline(userId) {
        return this.getUser(userId) !== 'disconnected';
    }

    getUser(userId) {
        if (this.usersMap.has(userId)) {
            return this.usersMap.get(userId);
        }
        return 'disconnected';
    }

}
