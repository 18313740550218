import React from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow, CTooltip
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import Http from "../../../utils/Http";
import Storage from "../../../utils/Storage";
import BaseScreen from "../../BaseScreen";
import AppError from "../../../error/AppError";

const moment = require('moment');

export default class LoginScreen extends BaseScreen {

    constructor() {
        super();

        // Frontend update teamcity trigger test.

        this.state = {
            authorizationModules: [],
            advancedAuthorizationModules: [],
            showAdvanced: false,
            version: '',
        }

        this.readEnabledAuthorizations = this.readEnabledAuthorizations.bind(this);
        this.login = this.login.bind(this);
    }

    login(auth, data) {
        Storage.setItem('user_name', data.user_name);

        super.showLoader();
        Http.post('/authenticate', {
            ...data,
            auth_id: auth.auth_id,
        }).then(response => {
            this.onLogged(response);
        }).catch(err => {
            console.log(err);
            if (err instanceof AppError && err.message === 'redirect') {
                return;
            }
            super.showError(err, "Přihlášení se nezdařilo", `Přihlášení se nepodařilo, zkuste později.`);
        }).finally(() => {
            super.hideLoader();
        });
    }

    componentDidMount() {
        super.componentDidMount();

        this.readEnabledAuthorizations()

        this.loggedUser();
    }

    readEnabledAuthorizations() {
        Http.get('/authorization/enabled').then(response => {
            const advancedAuth = [];
            const visibleAuth = [];
            for (const auth of response.list) {
                if (auth.auth_meta && auth.auth_meta.advanced === true) {
                    advancedAuth.push(auth);
                } else {
                    visibleAuth.push(auth);
                }
            }

            this.setState({
                ...this.state,
                authorizationModules: visibleAuth,
                advancedAuthorizationModules: advancedAuth
            })
        }).catch(err => {
            console.log(err);
        });
    }

    loggedUser() {
        console.log("Logged");
        const token = Storage.getItem('token');
        if (!token) {
            return;
        }
        super.showLoader();
        Http.get('/authentication/me').then(response => {
            this.onLogged(response);
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            super.hideLoader();
        });
    }

    onLogged(response) {
        console.log(response.version);
        console.log("onLogged");
        Storage.set('token', response.auth.access_token);
        Storage.set('version', response.version);
        Storage.setItem('token', response.auth.access_token);

        Storage.set('user', response.user);
        Storage.set('accessRights', response.accessRights);
        Storage.set('blogs', response.blogs);

        this.socketLogin(response.user);

        // TODO fix infinite loop -> reservation fullscreen -> reload
        const lastUrl = Storage.getItem('lastUrl');
        if (lastUrl && lastUrl.indexOf('/login') === -1) {
            window.location = lastUrl;
        } else {
            window.location = '/#/intranet/posts';
            return;
        }
    }

    socketLogin(user) {
        const socket = Storage.get('socket');
        socket.on('error', (err) => {
            console.log(err);
            // window.showError(Http.translateErrorMessage(err, 'Neznámá chyba protokolu socket-io.'));
        });

        socket.connectSocket(user);
    }

    doAuthorization(auth) {

    }

    renderAuth(auth) {
        return <><CButton
            color="success"
            onClick={() => {
                Storage.setItem('token', null);
                this.login(auth, {
                    user_name: document.getElementById('login').value,
                    password: document.getElementById('password').value
                });
            }}
            style={{
                marginBottom: '0.5rem',
                minWidth: '75%',
                height: '2.5rem',
                backgroundColor: auth.auth_meta && auth.auth_meta.color ? auth.auth_meta.color : undefined,
                borderColor: auth.auth_meta && auth.auth_meta.color ? auth.auth_meta.color : undefined,
            }}
            className="px-4"><span
            data-cy={`btn_login_${auth.auth_module}`}>{auth.auth_name}</span></CButton>
            <br/>
        </>
    }

    render() {
        const userName = Storage.getItem('user_name');

        return (
            <div className="c-app c-default-layout flex-row align-items-center">
                {this.baseHtml()}
                <CContainer>
                    <CRow className="justify-content-center">
                        <CCol md="5">
                            <CCardGroup>
                                <CCard className="p-4">
                                    <CCardBody>
                                        <CForm>
                                            <h1>Přihlášení</h1>
                                            <p className="text-muted">Přihlaste se ke svému účtu</p>
                                            <CInputGroup className="mb-3">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>
                                                        <CIcon name="cil-user"/>
                                                    </CInputGroupText>
                                                </CInputGroupPrepend>
                                                <CInput id={'login'}
                                                        type="text"
                                                        placeholder="Uživatelské jméno"
                                                        defaultValue={userName}
                                                        onKeyUp={(eve) => {
                                                            if (eve.keyCode === 13) {
                                                                this.login(this.state.authorizationModules[0], {
                                                                    user_name: document.getElementById('login').value,
                                                                    password: document.getElementById('password').value
                                                                });
                                                            }
                                                        }}
                                                        autoComplete="username"/>
                                            </CInputGroup>
                                            <CInputGroup className="mb-4">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>
                                                        <CIcon name="cil-lock-locked"/>
                                                    </CInputGroupText>
                                                </CInputGroupPrepend>
                                                <CInput id={'password'} type="password" p
                                                        laceholder="Heslo"
                                                        placeholder="Uživatelské heslo"
                                                        autoComplete="current-password"
                                                        onKeyUp={(eve) => {
                                                            if (eve.keyCode === 13) {
                                                                this.login(this.state.authorizationModules[0], {
                                                                    user_name: document.getElementById('login').value,
                                                                    password: document.getElementById('password').value
                                                                });
                                                            }
                                                        }}
                                                />
                                            </CInputGroup>
                                            <CRow>
                                                {/*<CCol xs="12" md="12">*/}
                                                <div style={{textAlign: 'center', width: '100%'}}>
                                                    {this.state.authorizationModules.map(auth => {
                                                        return this.renderAuth(auth)
                                                    })}


                                                    {!this.state.showAdvanced && this.state.advancedAuthorizationModules.length > 0 ?
                                                        <span data-cy={"btn_more_auth"} style={{cursor: 'pointer', color: '#a9a9a9'}}
                                                              onClick={() => {
                                                                  this.setState({
                                                                      ...this.state,
                                                                      showAdvanced: true
                                                                  })
                                                              }}>další ...</span> : null}

                                                    {this.state.showAdvanced ? this.state.advancedAuthorizationModules.map(auth => {
                                                        return this.renderAuth(auth)
                                                    }) : null}
                                                </div>
                                            </CRow>
                                        </CForm>
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                        </CCol>
                    </CRow>
                </CContainer>

                {/*<CTooltip content={`Verze aplikace`}>*/}
                {/*    <div style={{*/}
                {/*        color: '#ccc',*/}
                {/*        position: 'absolute',*/}
                {/*        bottom: '0.3rem',*/}
                {/*        left: '0.3rem'*/}
                {/*    }}>{this.state.version}</div>*/}
                {/*</CTooltip>*/}
            </div>
        )
    }
}

