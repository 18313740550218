import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import {CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";
import Socket from './utils/Socket';
import Storage from './utils/Storage';
import Users from './utils/Users'
import LoginScreen from "./intranet/structure/screens/LoginScreen";
import MeetingRoomDetailScreen from "./intranet/structure/screens/reservation/MeetingRoomDetailScreen";
import Loader from "./intranet/structure/components/loader/Loader";
import ErrorBoundary from "./ErrorBoundary";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"/>
    </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const socket = new Socket();
const users = new Users(socket);

Storage.set('socket', socket);
Storage.set('users', users);

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loaderMessage: false
        };
        this._loader = React.createRef();

        socket.on('logged', data => {
            console.log("Logged!!");
        });
        socket.on('disconnect', data => {
            console.log("Disconnected.");
        });
    }

    componentDidMount() {
        window.showError = (message) => {
            this.setState({
                ...this.state,
                error: `${message}`,
            })
        };

        window.showLoading = (title) => {
            if (title) {
                this._loader.current.show(title);
            } else {
                this._loader.current.hide();
            }
        };

        setInterval(() => {
            // Auto reconnect
            if (Storage.get('socket') && !Storage.get('socket').isConnected() && Storage.get('socket').socket) {
                Storage.get('socket').socket.connect()
            }
        }, 1000 * 60)

        if (window.location.search.indexOf('ext-token') !== -1) {
            const token = window.location.search.substring('?ext-token='.length);
            Storage.setItem('token', token);
        }

        Storage.setItem('lastUrl', window.location.href);
        window.location = '/#/login'
    }

    errorModal() {
        return <CModal
            show={this.state.error}
            onClose={() => {
                this.setState({...this.state, error: null})
            }}
            color="danger"
        >
            <CModalHeader closeButton>
                <CModalTitle>Chyba</CModalTitle>
            </CModalHeader>
            <CModalBody>
                {this.state.error}
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => {
                    this.setState({...this.state, error: null})
                }}>Zavřít</CButton>
            </CModalFooter>
        </CModal>
    }

    render() {
        return (
            [
                <ErrorBoundary>
                    <HashRouter>

                        <React.Suspense fallback={loading}>
                            <Switch>
                                <Route path="/login" name="Přihlašovací stránka"
                                       render={props => <LoginScreen {...props}/>}/>
                                <Route exact path="/register" name="Register Page"
                                       render={props => <Register {...props}/>}/>
                                <Route exact path="/reservations-simple" name="Rezervační systém"
                                       render={props => <MeetingRoomDetailScreen {...props}/>}/>
                                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
                                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
                                <Route path="/" name="Home" render={props => <TheLayout {...props}/>}/>
                            </Switch>
                        </React.Suspense>
                    </HashRouter>
                </ErrorBoundary>,
                this.errorModal(),
                <Loader ref={this._loader} key={"MainLoader"}/>
            ]
        );
    }
}

export default App;
