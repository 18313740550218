import React from 'react'
import Calendar from "../../components/calendar/Calendar";
import ReservationScreen from "./ReservationScreen";
import './meeting-room-reservation-simple.scss'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CRow,
    CButtonGroup,
    CSidebarBrand, CImg, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdownDivider, CCardHeader
} from "@coreui/react";
import UserStatus from "../../components/misc/UserStatus";
import moment from "moment";
import DateCountDown from "../../components/misc/DateCountDown";
import Storage from "../../../../utils/Storage";
import queryString from "query-string";
import Http from "../../../../utils/Http";
import CalendarReservation from "../../components/unsorted/CalendarReservation";
import CIcon from "@coreui/icons-react";
import TablePanel from "../../components/tabs/TablePanel";

export default class MeetingRoomDetailScreen extends ReservationScreen {

    constructor() {
        super();

        this.state = {
            selectedMeetingRoom: undefined,
            calendarDate: new Date(),
            calendarView: 'day',
            events: [],
            guestPassword: 'nenačteno',
            meeting_rooms: [],
        };
        this._lastUserActionTime = new Date().getTime();
        this._calendarReservation = React.createRef();

        this.onEventSave = this.onEventSave.bind(this);
        this.onSelectSlot = this.onSelectSlot.bind(this);
        this.onSelectEvent = this.onSelectEvent.bind(this);
        this.endCurrentEvent = this.endCurrentEvent.bind(this);
        this.reloadReservations = this.reloadReservations.bind(this);
        this.onDeleteEvent = this.onDeleteEvent.bind(this);
        this.createAdhocReservation = this.createAdhocReservation.bind(this);
        this.onMessage = this.onMessage.bind(this);
        this.reloadGuestWifiPassword = this.reloadGuestWifiPassword.bind(this);
        this.loadReservations = this.loadReservations.bind(this);


        this.socket = Storage.get('socket');
    }

    // 1. Deaktivovat událost
    // 2. Přidat událost

    componentDidMount() {
        super.componentDidMount();
        this.socket.on('message', this.onMessage);

        const query = queryString.parse(this.props.location.search);
        this.reload(query.mr_id);
    }

    reload(mr_id){
        this.mr_id = mr_id;

        this.reloadReservations(mr_id);

        clearInterval(this._viewRestoreTimer);
        this._viewRestoreTimer = setInterval(() => {
            if ((new Date().getTime()) - this._lastUserActionTime > 60 * 1000) {
                this.setState({
                    calendarDate: new Date(),
                    calendarView: 'day'
                });
            }
        }, 1000 * 35);


        clearInterval(this._reservationRefresh);
        this._reservationRefresh = setInterval(() => {
            this.reloadReservations(this.mr_id);
        }, 1000 * 60 * 10);

        clearInterval(this._wifiRefresh);
        this._wifiRefresh = setInterval(() => {
            this.reloadGuestWifiPassword();
        }, 1000 * 60 * 60 * 0.5);


        var elements = document.getElementsByClassName('rbc-btn-group');
        if (elements.length > 1) {
            elements[1].childNodes[0].style.visibility = "hidden"
            elements[1].childNodes[3].style.display = "none"
        }

        this.reloadGuestWifiPassword();
        this.loadMeetingRooms();
    }

    loadMeetingRooms() {
        Http.get('/meeting-rooms?order=mr_name').then(data => {
            this.setState({
                ...this.state,
                meeting_rooms: data.list,
                selectedMeetingRoom: data.list.find(it => {
                    return it.mr_id == this.mr_id
                })
            })
        });
    }

    componentWillUnmount() {
        clearInterval(this._reservationRefresh);
        clearInterval(this._viewRestoreTimer);
        clearInterval(this._wifiRefresh);
        this.socket.removeListener('message', this.onMessage, false);
    }

    loadReservations(mrId) {
        const loaderId = super.showLoadingBar('Načítám seznam rezervací, čekejte prosím.');
        this.setState({...this.state, events: []}, () => {
            const range = this.getFromTo(this.state.calendarDate, this.state.calendarView);
            return super.loadReservations(mrId, range[0], range[1]).then((events) => {
                console.log("Here ...");
                console.log(events);
                this.setState({...this.state, events: events})
            }).catch(err => {
                console.log(err);
                super.showErrorBar(err.message);
            }).finally(() => {
                super.hideLoadingBar(loaderId);
            });
        })

    }

    reloadReservations(mrId) {
        this.loadReservations(mrId);
        this.lodMeetingRoomState(mrId).then(data => {
            this.setState({
                ...this.state,
                actualReservations: data.actual,
                nextReservations: data.next,
                lastRefresh: new Date(),
            })
        })
    }


    reloadGuestWifiPassword() {
        // Do not update password more than once in 10hours. 6:30->18:30
        if (this.state.lastWifiSuccessUpdate && (new Date().getTime() - this.state.lastWifiSuccessUpdate.getTime()) < 1000 * 3600 * 12) {
            return;
        }

        const axios = require('axios');
        // https://radius.neit.cz/guest/?json -> using VPN
        // https://radius.int.neit.cz/guest/?json -> no access rights for now
        axios.get('https://radius.int.neit.cz/guest/?json', {})
            .then((response) => {
                const data = response.data;
                this.setState({
                    ...this.state,
                    guestPassword: data.password,
                    lastWifiUpdate: new Date(),
                    lastWifiSuccessUpdate: new Date(),
                });
            }).catch(err => {
            this.setState({
                ...this.state,
                guestPassword: '-chyba-',
                lastWifiUpdate: new Date(),
            });
            // TODO send to backend.
            super.showErrorBar(err.message);
            console.log(err);
        })
    }

    onMessage(data) {
        if (data.key === 'meeting_room_reservation_changed') {
            if (Number(data.mr_id) === Number(this.mr_id)) {
                this.reloadReservations(this.mr_id);
            }
        }
    }

    renderNext() {
        if (!this.state.nextReservations || this.state.nextReservations.length === 0) {
            return 'Žádné dnešní rezervace'
        }
        const next = this.state.nextReservations[0];
        const nextStart = new Date(next.start);
        const today = new Date();
        if (today.getDate() !== nextStart.getDate() || today.getMonth() !== nextStart.getMonth() || today.getYear() !== nextStart.getYear()) {
            return 'Žádné dnešní rezervace'
        }

        return <div>
            <b>Následující: </b>{moment(next.start).format('HH:mm')} - {moment(next.end).format('HH:mm')} {next.title}
        </div>
    }

    endCurrentEvent() {
        if (this.state.actualReservations.length === 0) {
            return;
        }
        const event = this.state.actualReservations[0];
        if (event) {
            const loaderId = super.showLoadingBar('Ukončuji rezervaci, čekejte prosím.');
            return super.deleteEvent(event.mrs_id).catch(err => {
                super.showErrorBar(err.message);
            }).finally(() => {
                super.hideLoadingBar(loaderId);
            });
        }
    }

    createAdhocReservation(duration = 30) {
        const start = new Date().getTime() - 1000 * 10;
        const end = new Date().getTime() + 1000 * 60 * duration;
        const event = {
            start: start,
            end: end,
            mr_id: this.mr_id,
            title: `Ad hoc rezervace ${duration} minut nebo do ukončení.`,
            description: ""
        };

        const loaderId = super.showLoadingBar('Ukládám rezervaci, čekejte prosím.');
        return super.saveEvent(event).catch(err => {
            super.showErrorBar('Rezervaci se nepodařilo uložit. ' + err.message);
        }).finally(() => {
            super.hideLoadingBar(loaderId);
        });
    }

    renderActual() {
        if (!this.state.actualReservations) {
            return <CCard color={"secondary"}>
                <CCardBody>
                    Načítám data.
                </CCardBody>
            </CCard>
        }
        if (this.state.actualReservations.length > 0) {
            return <CCard color={"danger"} style={{color: 'white'}}>
                <CCardBody>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{flexGrow: 1}}>
                            <span style={{
                                fontSize: '1.1rem',
                                fontWeight: 'bold'
                            }}>Obsazeno:</span> {this.state.actualReservations[0].title}
                            <br/>
                            {this.state.actualReservations[0].description}
                            <br/><br/>
                            Konec za: <DateCountDown untilDate={new Date(this.state.actualReservations[0].end)}/>
                        </div>
                        <div style={{flexShrink: 0}}>
                            <CButton onClick={this.endCurrentEvent}
                                     color="success">Ukončit tuto rezervaci</CButton>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        }

        const allAvailableMinutes = [15, 30, 60];
        const nextStart = this.state.nextReservations.length > 0 ? new Date(this.state.nextReservations[0].start) : null;
        let choices = []; // minutes
        if (!nextStart) {
            choices = allAvailableMinutes; // minutes
        } else {
            const minutesToStart = (nextStart.getTime() - new Date().getTime()) / 1000 / 60;
            for (const minutes of allAvailableMinutes) {
                if (minutes < minutesToStart) {
                    choices.push(minutes);
                }
            }
        }

        return <CCard color={"success"} style={{color: 'white'}}>
            <CCardBody>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{flexGrow: 1, fontSize: '1.1rem'}}>
                        Zasedačka je volná.
                    </div>
                    <div style={{flexShrink: 0}}>
                        <CButtonGroup>
                            <CDropdown color="danger">
                                <CDropdownToggle color="danger">
                                    Vstoupit do místnosti&nbsp;&nbsp;<CIcon
                                    name="cil-clock"/>
                                </CDropdownToggle>
                                <CDropdownMenu color="danger">
                                    {choices.length > 0 ? choices.map(it => {
                                        return (<CDropdownItem onClick={this.createAdhocReservation.bind(this, it)}>Vstoupit
                                            na {it} minut</CDropdownItem>)
                                    }) : <CDropdownItem>Zasedačka je v následující hodině obsazena.</CDropdownItem>}
                                </CDropdownMenu>
                            </CDropdown>
                        </CButtonGroup>
                    </div>
                </div>
            </CCardBody>
        </CCard>
    }


    onDeleteEvent(event) {
        this._lastUserActionTime = new Date().getTime();
        const loaderId = super.showLoadingBar('Ukončuji rezervaci, čekejte prosím.');
        super.deleteEvent(event.mrs_id).catch(err => {
            super.showErrorBar('Rezervaci se nepodařilo smazat' + err.message);
        }).finally(() => {
            super.hideLoadingBar(loaderId);
        });
    }


    onSelectSlot(data) {
        this._lastUserActionTime = new Date().getTime();
        this._calendarReservation.current.show({
            start: data.start.getTime(),
            end: data.end.getTime(),
            mr_id: this.mr_id
        });
    }

    onSelectEvent(event) {
        this._lastUserActionTime = new Date().getTime();
        this._calendarReservation.current.show(event);
    }

    onEventSave(event) {
        const loaderId = super.showLoadingBar('Ukládám rezervaci, čekejte prosím.');
        this._lastUserActionTime = new Date().getTime();
        return super.saveEvent(event).catch(err => {
            super.showErrorBar('Rezervaci se nepodařilo uložit ' + err.message);
        }).finally(() => {
            super.hideLoadingBar(loaderId);
        });
    }

    render() {
        const min = new Date();
        min.setMinutes(0);
        min.setHours(7);

        const max = new Date();
        max.setMinutes(0);
        max.setHours(19);

        return (
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {this.baseHtml()}
                {/*<CImg*/}
                {/*    width={245}*/}
                {/*    height={52}*/}
                {/*    id={"brand_logo_reservations"}*/}
                {/*    style={{position: 'absolute', left: '1rem', top: '1rem'}}*/}
                {/*    src={'avatars/logo_neit_consulting_horizontal.png'}*/}
                {/*    alt=""/>*/}

                <CalendarReservation ref={this._calendarReservation} onSave={this.onEventSave}
                                     onDelete={this.onDeleteEvent}/>

                <span onClick={() => {
                    this.reloadReservations(this.mr_id)
                }}
                      style={{color: '#cecece', left: 0, bottom: 0, position: "absolute"}}>
                    Aktualizováno: {this.state.lastRefresh ? moment(this.state.lastRefresh).format("DD.MM HH:mm:ss") : 'nikdy'}
                </span>
                <div style={{width: '50%'}}>
                    <div className={"meetingRoomDetailList"}>
                        {this.state.meeting_rooms.map(mr => {
                            return (
                                <div className={this.state.selectedMeetingRoom.mr_id === mr.mr_id ? 'active' : ''}
                                     onClick={() => {
                                         this.reload(mr.mr_id);
                                     }}>
                                    {mr.mr_name}
                                </div>
                            )
                        })}
                    </div>

                    <div className="c-app c-default-layout flex-row align-items-center">
                        {this.baseHtml()}
                        <CContainer>
                            <CRow className="justify-content-center">
                                <CCol md="10">
                                    <CCardGroup>
                                        <CCard className="p-2">
                                            <CCardBody>
                                                <h2>WiFi - Guest heslo</h2>
                                                <div style={{
                                                    textAlign: 'center',
                                                    fontSize: '2rem',
                                                }}>
                                                    <span
                                                        onClick={this.reloadGuestWifiPassword}>{this.state.guestPassword}</span>
                                                </div>
                                            </CCardBody>

                                            <div style={{
                                                position: 'absolute',
                                                left: '0.2rem',
                                                bottom: '0',
                                                color: '#e1e1e1',
                                                margin: '0.2rem'
                                            }}>
                                                <span onClick={this.reloadGuestWifiPassword}>
                                                Aktualizováno: {this.state.lastWifiUpdate ? moment(this.state.lastWifiUpdate).format("DD.MM HH:mm:ss") : 'nikdy'}
                                                </span>
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                right: '0.2rem',
                                                bottom: '0',
                                                color: '#ccc',
                                                margin: '0.2rem'
                                            }}>
                                                Přihlašovací jméno: guest
                                            </div>
                                        </CCard>
                                    </CCardGroup>
                                </CCol>
                            </CRow>
                            <br/>

                            <CRow className="justify-content-center">
                                <CCol md="10">
                                    <CCardGroup>
                                        <CCard className="p-2">
                                            <CCardBody>
                                                <CForm>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center'
                                                    }}>
                                                        <UserStatus
                                                            style={{width: '7px', height: '7px'}}/>
                                                        &nbsp;&nbsp;
                                                        <h2>Rezervační systém</h2>
                                                    </div>
                                                    <br/>

                                                    {this.renderActual()}
                                                    {this.renderNext()}
                                                </CForm>
                                            </CCardBody>


                                        </CCard>
                                    </CCardGroup>
                                </CCol>
                            </CRow>

                            {/*<br />*/}
                            {/*<CRow className="justify-content-center">*/}
                            {/*    <CCol md="10">*/}
                            {/*        <CCardGroup>*/}
                            {/*            <CCard className="p-2">*/}
                            {/*                <CCardBody>*/}
                            {/*                    <h2>Události</h2>*/}
                            {/*                    <div style={{*/}
                            {/*                        textAlign: 'center',*/}
                            {/*                        fontSize: '2rem',*/}
                            {/*                    }}>*/}
                            {/*                    </div>*/}
                            {/*                    <ul>*/}
                            {/*                        <li>🎄 22.10.2022 - Vánoční večírek</li>*/}
                            {/*                        <li>🚗 24.12.2022 - Motokáry</li>*/}
                            {/*                    </ul>*/}
                            {/*                </CCardBody>*/}
                            {/*            </CCard>*/}
                            {/*        </CCardGroup>*/}
                            {/*    </CCol>*/}
                            {/*</CRow>*/}

                        </CContainer>
                    </div>
                </div>
                <div style={{width: '50%'}}>
                    <Calendar
                        messages={{
                            date: 'Datum',
                            time: 'Čas',
                            event: 'Agenda',
                            allDay: 'Celý den',
                            week: 'Týden',
                            work_week: 'Pracovní týden',
                            day: 'Den',
                            month: 'Měsíc',
                            previous: '<',
                            next: '>',
                            yesterday: 'Včera',
                            tomorrow: 'Zítra',
                            today: 'Dnes',
                            agenda: 'Agenda',
                            noEventsInRange: 'V tomto rozmezí nejsou žádné události.',
                            showMore: function showMore(total) {
                                return "+" + total + " další";
                            }
                        }}
                        culture='cs'
                        min={min}
                        max={max}
                        view={this.state.calendarView}
                        onView={(view, a) => {
                            this._lastUserActionTime = new Date().getTime();
                            this.setState({...this.state, calendarView: view}, () => {
                                this.loadReservations(this.mr_id)
                            });
                        }}
                        onNavigate={(date, view, action) => {
                            this._lastUserActionTime = new Date().getTime();
                            this.setState({...this.state, calendarDate: date}, () => {
                                this.loadReservations(this.mr_id)
                            });

                        }}
                        date={this.state.calendarDate}
                        onSelectSlot={this.onSelectSlot}
                        onSelectEvent={this.onSelectEvent}
                        selectable={true}
                        events={this.state.events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{height: '100vh'}}
                    />
                </div>
            </div>
        )
    }
}

