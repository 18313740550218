import React from "react";
import {BarLoader} from "react-spinners";

export default class BarLoaderComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: !!this.props.loading,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading !== this.props.loading) {
            this.setState({
                ...this.state,
                isLoading: !!this.props.loading,
            });
        }
    }

    show(){
        this.setState({
            ...this.state,
            isLoading: true,
        });
    }

    hide(){
        this.setState({
            ...this.state,
            isLoading: false,
        });
    }

    render() {
        const override = `
          margin: 0 auto;
          background-color: rgba(0,0,0,0);
        `;

        return (this.state.isLoading ? <BarLoader
            color={'#999'} loading={true} css={override} width={'100%'} height={3}/> : <div style={{height: '3px'}}/>)
    }
}
