import BarLoaderComponent from "./BarLoaderComponent";
import React from "react";

export default class ModalLoader extends React.Component {

    constructor(props) {
        super(props)
        this._loadingContainer = React.createRef();

    }

    show() {
        this._loadingContainer.current.show();
    }

    hide() {
        this._loadingContainer.current.hide();
    }

    render() {
        return <div style={{display: 'flex', flexDirection: 'row', margin: '3px'}}>&nbsp;&nbsp;&nbsp;
            <BarLoaderComponent ref={this._loadingContainer}/>
            &nbsp;&nbsp;&nbsp;</div>
    }
}

