import React from "react";


export default class TabButtons extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            buttons: this.props.buttons,
            activeTab: Array.isArray(this.props.buttons) && this.props.buttons.length > 0 ? this.props.buttons[0].id : null
        }

    }

    activateTab(tab) {
        this.setState({
            ...this.state,
            activeTab: tab.id
        }, () => {
            this.props.onTabChange && this.props.onTabChange(tab);
        });
    }

    render() {
        return (
            <div className={"tabButtons"}>
                {this.state.buttons.map(tab => {
                    if (this.state.activeTab === tab.id) {
                        return <div className={"tabButton tabButtonActive"}>{tab.title}</div>
                    } else {
                        return <div onClick={this.activateTab.bind(this, tab)}
                                    className={"tabButton"}>{tab.title}</div>
                    }

                })}
            </div>
        )
    }
}
