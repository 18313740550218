import {CButton, CFormGroup, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle,} from "@coreui/react";

import React from "react";
import ModalLoader from "../../loader/ModalLoader";

export default class YesNoModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        }

        this.hide = this.hide.bind(this);
        this.show = this.show.bind(this);
        this.onYes = this.onYes.bind(this);
        this.onNo = this.onNo.bind(this);
    }

    hide() {
        this.setState({
            ...this.state,
            visible: false,
        });
    }

    show(message, onYes, onNo) {
        this.setState({
            ...this.state,
            visible: true,
            message: message,
            onYes: onYes,
            no: onNo
        });
    }

    onYes() {
        if (this.state.onYes) {
            this.state.onYes();
        }
        this.hide();
    }

    onNo() {
        if (this.state.onNo) {
            this.state.onNo();
        }
        this.hide();
    }

    render() {
        return (
            <CModal
                closeOnBackdrop={false}
                show={this.state.visible}
                onClose={this.hide}
                color="success"
            >
                <CModalHeader closeButton>
                    <CModalTitle>
                        Vyberte volbu
                    </CModalTitle>
                </CModalHeader>
                <ModalLoader ref={this._loadingContainer}/>
                <CModalBody>
                    <CFormGroup>
                        {this.state.message}
                    </CFormGroup>
                </CModalBody>
                <CModalFooter>
                    <CButton color="success" onClick={this.onYes}><span data-cy={'ynd_bnt_yes'}>Ano</span></CButton>
                    <CButton color="secondary" onClick={this.onNo}><span data-cy={'ynd_bnt_no'}>Ne</span></CButton>
                </CModalFooter>
            </CModal>
        )
    }
}
