import React from 'react'
import CIcon from "@coreui/icons-react";
import './info-bar.scss';

const _ = require('lodash');

export default class InfoBar extends React.Component {

    constructor(props) {
        super(props);

        this.closeBox = this.closeBox.bind(this);

        this.state = {
            bars: {},
            durations: {},
        }
    }

    componentDidMount() {
        setInterval(() => {
            const newItervals = {};
            for (const bar of _.values(this.state.bars)) {

                newItervals[bar.id] = Math.floor(Math.max((bar.duration - (new Date().getTime() - bar.start.getTime())) / 1000, 0));
            }
            this.setState({
                ...this.state,
                durations: newItervals
            })
        }, 750)
    }

    closeBox() {
        this.setState({
            ...this.state,
            bars: {}
        })
    }

    showBar(message, type, duration) {
        const id = `bar_${Math.random()}`;

        if (duration) {
            setTimeout(() => {
                const bars = this.state.bars;
                delete bars[id];
                this.setState({
                    ...this.state,
                    bars,
                });
            }, duration);
        }

        const bars = this.state.bars;
        bars[id] = {
            id: id,
            type: type,
            message: message,
            start: new Date(),
            duration,
        }
        this.setState({
            ...this.state,
            bars,
        });
        return id;
    }

    hideLoading(id) {
        const bars = this.state.bars;
        delete bars[id];
        this.setState({
            ...this.state,
            bars,
        });
    }

    showLoading(message, duration = 1000 * 60) {
        return this.showBar(message, 'loading', duration);
    }

    showError(message, duration = 10 * 1000) {
        return this.showBar(message, 'error', duration);
    }

    renderError(bar, index) {
        const bottom = 0.3 + 2.9 * index;
        return (<div className={"info-box-error"} style={{
            bottom: `${bottom}rem`
        }}>
            <div style={{
                marginLeft: '1rem',
                padding: '0.3rem',
                flexGrow: '1',
            }}>
                <CIcon style={{color: 'white'}} name={'cil-warning'}
                       height={'1.5rem'}/>

                &nbsp;&nbsp;&nbsp;<b>Chyba:</b>
                <span style={{marginLeft: '0.5rem'}}>
                    {bar.message}
                    </span>
            </div>
            <div style={{flexShrink: '0', marginRight: '1rem'}}>{this.state.durations[bar.id]}s</div>
            <div style={{flexShrink: '0', marginRight: '1rem'}} onClick={() => {
                this.hideLoading(bar.id);
            }}>
                <div className={"info-box-error-close"}> Zavřít&nbsp;&nbsp;X
                </div>
            </div>

        </div>)
    }

    renderLoading(bar, index) {
        const bottom = 0.3 + 2.9 * index;
        return (<div className={"info-box-loading"} style={{
            bottom: `${bottom}rem`
        }}>
            <div style={{
                marginLeft: '1rem',
                padding: '0.3rem',
                display: 'flex',
                flexGrow: '1',
            }}>
                <div className="loader" style={{marginTop: '0.4rem'}}/>

                &nbsp;&nbsp;&nbsp;<b>Načítám:</b>
                <span style={{marginLeft: '0.5rem'}}>
                    {bar.message}
                    </span>
            </div>
            <div style={{flexShrink: '0', marginRight: '1rem'}} onClick={this.closeBox}>
                <div className={"info-box-loading-close"}> Zavřít&nbsp;&nbsp;X
                </div>
            </div>

        </div>)
    }

    render() {

        const barHtml = _.values(this.state.bars).map((bar, index) => {
            if (bar.type === 'loading') {
                return this.renderLoading(bar, index);
            } else if (bar.type === 'error') {
                return this.renderError(bar, index);
            }
        });
        return barHtml;
    }
}

