import {CButton, CInput, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CTextarea} from "@coreui/react";
import React from "react";

import '../../css/react-datepicker.css';

const moment = require('moment');

export default class CalendarReservation extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            event: this.props.event || {start: new Date(), end: new Date()}
        }

        this.hide = this.hide.bind(this);
        this.show = this.show.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);

        this._component = React.createRef();
    }


    hide() {
        this.setState({
            ...this.state,
            visible: false,
        });
    }

    show(event) {
        this.setState({
            ...this.state,
            visible: true,
            event: event
        });
    }

    delete() {
        this.props.onDelete(this.state.event);
        this.hide();
    }

    save() {
        const event = {
            ...this.state.event,
            title: document.getElementById("eveTitle").value,
            description: document.getElementById("eveDescription").value
        }
        if (typeof event.start === 'object') {
            event.start = event.start.getTime();
        }
        if (typeof event.end === 'object') {
            event.end = event.end.getTime();
        }

        this.props.onSave(event);
        this.hide();
    }

    setStartDate(date) {
        this.setState({
            ...this.state,
            event: {
                ...this.state.event,
                start: date
            }
        });
    }

    setEndDate(date) {
        this.setState({
            ...this.state,
            event: {...this.state.event, end: date}
        })
    }

    isValid() {
        return !!this.state.event.title;
    }

    render() {

        const DateRenderer = ({value, onClick}, ref) => {
            return (
                <CButton onClick={onClick} ref={ref} color="success">{value}</CButton>
            )
        };

        return (
            <CModal
                closeOnBackdrop={false}
                show={this.state.visible}
                onClose={this.hide}
                color="success"
            >
                <CModalHeader closeButton>
                    <CModalTitle>
                        Událost
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>

                    <CInput
                        value={this.state.event ? this.state.event.title || '' : ''}
                        onChange={(el) => {
                            this.setState({
                                ...this.state,
                                event: {
                                    ...this.state.event,
                                    title: el.target.value,
                                }
                            });
                        }}
                        id={"eveTitle"}
                        placeholder="Název události"
                        required/>
                    <br/>
                    <CTextarea
                        defaultValue={this.state.event ? this.state.event.description || '' : ''}
                        name="textarea-input"
                        id={"eveDescription"}
                        rows="9"
                        placeholder="Popis události"
                    />

                    <input
                        type="time"
                        value={moment(this.state.event.start).format('HH:mm')}
                        onChange={(el) => {
                            if (!el.target.value) {
                                return;
                            }
                            const out = el.target.value.split(':');
                            const hours = out[0];
                            const minutes = out[1];

                            const start = new Date(this.state.event.start);
                            start.setMinutes(Number(minutes));
                            start.setHours(Number(hours));

                            this.setState({
                                ...this.state,
                                event: {
                                    ...this.state.event,
                                    start: start.getTime(),
                                }
                            });
                        }} required/> →
                    <input
                        type="time"
                        value={moment(this.state.event.end).format('HH:mm')}
                        onChange={(el) => {
                            if (!el.target.value) {
                                return;
                            }
                            const out = el.target.value.split(':');
                            const hours = out[0];
                            const minutes = out[1];

                            const end = new Date(this.state.event.end);
                            end.setMinutes(Number(minutes));
                            end.setHours(Number(hours));

                            this.setState({
                                ...this.state,
                                event: {
                                    ...this.state.event,
                                    end: end,
                                }
                            });
                        }} required/>
                    <input
                        type="date"
                        style={{float: 'right'}}
                        value={moment(this.state.event.start).format('YYYY-MM-DD')}
                        onChange={(el) => {
                            if (!el.target.value) {
                                return;
                            }
                            const out = el.target.value.split('-');
                            const year = out[0];
                            const month = out[1] - 1;
                            const day = out[2];

                            const start = new Date(this.state.event.start);
                            start.setFullYear(Number(year), Number(month), Number(day));
                            const end = new Date(this.state.event.start);
                            end.setFullYear(Number(year), Number(month), Number(day));

                            this.setState({
                                ...this.state,
                                event: {
                                    ...this.state.event,
                                    start: start.getTime(),
                                    end: start.getTime(),
                                }
                            });

                        }} required/>

                </CModalBody>
                <CModalFooter>
                    {this.state.event.mrs_id ? <CButton color="danger" onClick={this.delete}>Smazat</CButton> : null}
                    <CButton disabled={!this.isValid()} color="success" onClick={this.save}>Uložit</CButton>
                    <CButton color="secondary" onClick={this.hide}>Zrušit</CButton>
                </CModalFooter>
            </CModal>
        )
    }
}
