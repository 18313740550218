import React from "react";
import DateUtils from "../../../../utils/DateUtils";

export default class DateCountDown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            seconds: DateUtils.getSeconds(this.props.untilDate)
        }
    }

    componentDidMount() {
        this._timer = setInterval(() => {
            this.setState({
                ...this.state,
                seconds: DateUtils.getSeconds(this.props.untilDate)
            })
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this._timer);
    }

    render() {
        if (this.state.seconds < 0) {
            return <></>
        }
        return <>
            {DateUtils.formatSeconds(this.state.seconds, this.props.shortFormat)}
        </>
    }

}
